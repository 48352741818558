.ova-card {
	transition: all .3s ease;
	-moz-transition: all .3s ease;
	-o-transition: all .3s ease;
	padding: 32px 28px;
	background-color: #fff;
    border-radius: 6px;
    &:hover {
    	transition: all .3s ease;
		-moz-transition: all .3s ease;
		-o-transition: all .3s ease;
       	box-shadow: 0px 28px 60px rgba(0, 0, 0, 0.07);
    }
	.icon {
        i {
         	font-size: 52px;
         	color: var(--primary);
        }
        padding-bottom: 24px;
	}
	.title {
		margin: 0;
		padding-bottom: 24px; 
        font-weight: 600;
		font-size: 22px;
		line-height: 26px;
		text-transform: capitalize;
		font-feature-settings: 'pnum' on, 'lnum' on;
		color: var(--heading);
	}
	.description {
		margin: 0;
        font-weight: 400;
		font-size: 16px;
		line-height: 26px;
		color: var(--text);
	}
}