.ova-testimonial2 {
    padding-right: 70px;
	height: 992px;
	overflow-y: scroll;
	@media (max-width: 767px) {
		padding-right: 20px;
	}

	.item-testimonial2 {
		background-color: #fff;
		padding: 24px 28px;
		filter: drop-shadow(0px 12px 40px rgba(0, 0, 0, 0.04));

		&:not(:last-child) {
			margin-bottom: 30px;
		}
        
		&:nth-child(2n) {
			.client_info {
				flex-direction: row-reverse;
				.client-img {
					margin-right: 0;
					margin-left: 20px;
				}
				.name-job {
					.name {
						padding-left: 0;
						padding-right: 20px;
					    &:before {
					    	left: unset;
					    	right: 0;
					    }
					}
				}
			}
		}

		.client_info {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding-bottom: 24px;
			margin-bottom: 24px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.3);

			.client-img {
				border-radius: 50%;
				display: block;
				width: 100px;
				height: 100px;
				margin-right: 20px;
			}

			.name-job {
				.name {
					margin: 0;
					font-size: 22px;
					padding-left: 20px;
					font-family: var(--secondary-font);
					font-weight: 600;
					line-height: 26px;
					font-feature-settings: 'pnum' on, 'lnum' on;
					margin-bottom: 7px;
					color: var(--heading);
					position: relative;
					&:before {
						position: absolute;
						content: '';
						bottom: 10px;
						left: 0;
						width: 10px ;
						height: 4px;
						background-color: var(--primary) ;
					}
				}
				.job {
					margin: 0;
					font-weight: 400;
					font-size: 16px;
					line-height: 26px;
					color: var(--heading);
				}
			}
		}
		.testimonial {
			margin: 0;
			font-weight: 400;
			font-size: 16px;
			line-height: 26px;
			color: var(--text);
		}

	}

	// Scroll bar css
	&::-webkit-scrollbar {
	    width: 10px;
	}
	&::-webkit-scrollbar-track {
		background: #ececec; 
		border-radius: 5px;
	}
	&::-webkit-scrollbar-thumb {
		background: #a0a0a0; 
		border-radius: 5px; 
	}
}
