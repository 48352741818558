.ova-transportation {
	position: relative;
    transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;

	.transportation-img {
		position: relative;
		img {
           vertical-align: middle;
		   width: 100%;
		}
		.mask {
			transition: all .5s ease;
		    -moz-transition: all .5s ease;
		    -o-transition: all .5s ease;
			position: absolute;
		    content: '';
		    width: 100%;
		    height: 100%;
		    left: 0;
		    bottom: 0;
		    transition: all .3s ease;
		    background: linear-gradient(0deg, rgba(0, 0, 0, 0.14) 0%, rgba(0, 0, 0, 0) 33.33%);
		}
	}

	&:hover {
		.transportation-img {
			.mask {
				transition: all .5s ease;
			    -moz-transition: all .5s ease;
			    -o-transition: all .5s ease;
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 33.33%);
			}
		}
		.info {
			transition: all .3s ease;
			-moz-transition: all .3s ease;
			-o-transition: all .3s ease;
			bottom: 110px;
		}
		.content {
			transition: all .3s ease;
			-moz-transition: all .3s ease;
			-o-transition: all .3s ease;
			opacity: 1;
			top: calc(100% - 95px);
		}
	}

	.info {
		position: absolute;
		overflow: hidden;
		transition: all .3s ease;
		-moz-transition: all .3s ease;
		-o-transition: all .3s ease;
		left: 40px;
		bottom: 40px;

	    .title {
		    margin: 0;
		    padding: 0 30px 12px 22px;
		    color: #fff;
		    font-family: var(--secondary-font);
		    font-size: 28px;
		    font-weight: 700;
		    line-height: 40px;
		    font-feature-settings: 'pnum' on, 'lnum' on;
		    position: relative;
		    &:before {
		        position: absolute;
		        content: '';
		        width: 10px;
		        height: 22px;
		        left: 0;
		        bottom: 22px;
                border-radius: 7.5px 0px 0px 0px;
		        background: var(--primary);
		    }
		     &:after {
		        position: absolute;
		        content: '';
		        width: 238px;
		        height: 1px;
		        left: 0;
		        bottom: 0;
		        transition: all .5s ease;
		        background: #fff;
		    }
		}	
	}	

	.content {
		position: absolute;
		transition: all .3s ease;
		-moz-transition: all .3s ease;
		-o-transition: all .3s ease;
		opacity: 0;
		left: 40px;
		top: 100%;
		max-height: 80px;
        overflow-y: hidden;
         .description {
			margin: 0;
			padding-right: 30px;
			color: #fff;
			font-weight: 400;
			font-size: 16px;
			line-height: 26px;
        }
	}
}