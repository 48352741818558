.ova-office {
	transition: all .3s ease;      
	-moz-transition: all .3s ease; 
	-o-transition: all .3s ease; 
	background-color: #fff;
	padding: 28px;
	&:hover {
		transition: all .3s ease;      
		-moz-transition: all .3s ease; 
		-o-transition: all .3s ease;
		box-shadow: 0px 26px 66px rgba(0, 0, 0, 0.07);
	}

	a {
		font-family: var(--secondary-font);
		font-feature-settings: 'pnum' on, 'lnum' on;
		color: var(--heading);
		&:hover {
			color: var(--primary);
		}
	}

	.title {
		margin: 0;
		padding-left: 36px;
		margin-bottom: 20px;
		position: relative;
		font-weight: 600;
		font-size: 22px;
		line-height: 26px;
        &:before {
			position: absolute;
			content: "";
			border-radius: 50%; 
			left:0;
			top: 50%;
			transform: translateY(-50%); 
			width: 16px;
			height: 16px;
			background-color: var(--primary); 
		}
	}
    
    .heading {
    	margin: 0;
    	padding-bottom: 8px;
		font-weight: 600;
		font-size: 18px;
		line-height: 26px;
		color: var(--text);
		&.working_hours_heading {
			color: var(--heading);
			padding-bottom: 20px; 
		}
    }

	.divided {
		border-top: 1px solid rgba(0, 0, 0, 0.5);
		display: grid;
		padding-top: 32px;
		grid-template-columns: auto auto; 
		grid-column-gap: 40px; 
		@media (max-width: 1024px) {
           grid-template-columns: auto; 
		   grid-column-gap: 0; 
		}
		.phone {
			padding-bottom: 20px;
			a {
				font-size: 22px;
				line-height: 32px;
				font-weight: 700; 
				font-feature-settings: 'pnum' on, 'lnum' on;
			}
		}
		.email {
			padding-bottom: 20px;
			a {
				font-size: 18px;
				line-height: 27px;
				font-weight: 600; 
			}
		}
		.right {
			padding-left: 24px;
			position: relative;
			  &:before {
				position: absolute;
				content: "";
				left:0;
				top: 0;
				width: 1px;
				height: 153px;
				background-color: rgba(0, 0, 0, 0.3); 
			}
			@media (max-width: 1024px) {
	           padding-left: 0;
	           padding-bottom: 8px;
	           &:before {
	           	   content: none;
	           }
			}

			.working_hours {
				font-family: var(--secondary-font);
				font-weight: bold;
				font-size: 18px;
				line-height: 21px;
				font-feature-settings: 'pnum' on, 'lnum' on;
				color: var(--heading);
				padding-left: 19px;
				margin-bottom: 12px;
				position: relative;
				&:before {
					position: absolute;
					content: "";
					border-radius: 50%; 
					left:0;
					top: 50%;
					transform: translateY(-50%); 
					width: 7px;
					height: 7px;
					background-color: var(--text); 
				}
			}
		}
	}

	.address {
		a {
			font-size: 18px;
			line-height: 27px;
			font-weight: 600;
		}
	}

}