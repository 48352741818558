.ova-our-progress {
	.progress-list {
		display: grid;
		grid-template-columns: auto auto; 
		justify-content: start;
		grid-column-gap: 24px;  
		padding-bottom: 40px;
        position: relative;
		&:before {
			position: absolute;
			content: "";
			height: calc(100% - 62px);
			width: 0;
			left: 26px;
			bottom: -5px;
			border-left: 1px dashed #bbbbbb;
		}
        &:last-child{
            &:before{
                content: none;
            }
        }
			

		.text-number {
			font-family: var(--secondary-font);
    		font-size: 28px;
    		line-height: 40px;
    		font-weight: bold;
    		color: #fff;
    		font-feature-settings: 'pnum' on, 'lnum' on;
    		background-color: var(--primary);
    		width: 52px;
    		height: 52px;
    		border-radius: 50%;
    		display: flex;
    		justify-content: center;
    		align-items: center;
    	}

		.content {
			padding-top: 10px ;
		    .title {
		       	margin: 0;
		        color: var(--heading);
		        font-family: var(--secondary-font);
		        font-size: 22px;
		        font-weight: 700;
		        line-height: 32px;
		        padding-bottom: 38px;
		        a {
		        	color: var(--heading);
		        }
		        &:hover {
		        	a {
                       color: var(--primary);
		        	}
		        }
		    }

		    .text-list {
		    	p {
		    		margin: 0;
		    		padding-bottom: 20px;
		    	}

		    	ul {
		    		margin: 0;
		    		padding: 0; 
		    		padding-left: 20px; 
		    		li {
		    			font-family: var(--secondary-font);
				        font-size: 18px;
				        font-weight: 600;
				        line-height: 27px;
				        color: var(--heading);
		    			&::marker {
		    				color: var(--primary);
		    			}
		    			&:not(:last-child) {
		    				padding-bottom: 20px;
		    			}
		    		}
		    	}
			}
	    }
	}

}