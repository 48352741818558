.ova-contact-card {
	max-width: 306px;
	position: relative;

	.contact-card-img {
		img {
			vertical-align: middle;
		}
	}

	.content {
		position: absolute;
		top: 24px;
		left: 24px;
		.title {
			margin: 0; 
			margin-bottom: 18px;
			font-family: var(--primary-font);
			font-weight: bold;
			font-size: 28px;
			line-height: 40px;
			color: var(--heading);
		}
		.button-contact {
			transition: all .3s ease; 
			display: inline-flex;
			align-items: center; 
			background-color: var(--primary);
			border-radius: 3px;
			color: #fff;
			padding: 14px 20px;

			&:hover {
				box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
			}

			i {
				font-size: 18px;
				line-height: 1;
			}

			.text-button { 
				font-family: var(--secondary-font);
				display: inline-block; 
				padding-left: 10px;
				font-weight: 700;
				font-size: 18px;
				line-height: 23px;
			}
	    }
	}
	
}