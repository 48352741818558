.ova-video {
	display: inline-block;

	.ova-video-container {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;

		.video-content {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 72px;
			height: 72px;
			background-color: rgba(0, 0, 0, 0.5);
			border-radius: 50%;
			z-index: 1;
			cursor: pointer;
			-webkit-transition: all .3s ease;
			-moz-transition: all .3s ease;
			-o-transition: all .3s ease;
			transition: all .3s ease;

			i {
				color: #FFFFFF;
				font-size: 60px;
				vertical-align: middle;
				-webkit-transition: all .3s ease;
				-moz-transition: all .3s ease;
				-o-transition: all .3s ease;
				transition: all .3s ease;
			}

			&:hover {
				background-color: var(--secondary);
			}

			&.video-image {
				position: absolute;
			}
		}
	}

	.modal-container {
		display: none;
		background-color: rgba( 0, 0, 0, .2 );
		align-items: center;
		justify-content: center;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		z-index: 10;

		.modal {
		    width: 900px;
    		height: 500px;
    		position: relative;

    		@media screen and (max-width: 900px) {
    			width: 768px;
    			height: 450px;
    		}

    		@media screen and (max-width: 788px) {
    			width: 600px;
    			height: 350px;
    		}

    		@media screen and (max-width: 620px) {
    			width: 400px;
    			height: 250px;
    		}

    		@media screen and (max-width: 420px) {
    			width: 320px;
    			height: 200px;
    		}

    		@media screen and (max-width: 330px) {
    			width: 300px;
    			height: 180px;
    		}

    		i {
			    position: absolute;
			    right: -10px;
    			top: -35px;
    			padding: 10px;
    			cursor: pointer;
			    font-size: 12px;
			    color: #fff;
			    font-weight: 600;
			    -moz-transition: all .3s ease;
				-webkit-transition: all .3s ease;
				transition: all .3s ease;

				&:hover {
					color: #000;
				}
    		}

    		.modal-video {
    			width: 100%;
    			height: 100%;
    		}
		}
	}
}