.ova-icon-list {
	.icon-list-items {
		margin-bottom: 40px;
		.icon-list {
			display: inline-flex;
			justify-content: flex-start;
			align-items: flex-start;
			padding-bottom: 12px;

			.icon {
				display: flex;
				justify-content: center; 
				align-items: center;
                width: 64px;
                height: 64px;
                left: -10px;
                bottom: 5px;
                transition: all .3s ease;
                border-radius: 14px;
                box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.05);
                margin-right: 16px;
                @media (max-width: 767px) {
	            	box-shadow: unset;
	            }
                i {
                	color: var(--primary);
			        font-size: 35px;
                }
			}

		    .title {
		       	margin: 0;
		        color: var(--heading);
		        font-size: 20px;
		        font-weight: 600;
		        font-feature-settings: 'pnum' on, 'lnum' on;
		        padding-bottom: 12px;
		    }

		    .sub-title {
			    margin: 0;
			    font-size: 16px;
			}
	    }
		
		&:hover {
			.icon-list {
				.title {
				    a {
                        transition: all .3s ease;
						-moz-transition: all .3s ease;
					   	color: var(--primary);
				    }
				}
		    }
	    }
	}

	&.ova-icon-list-template2 {

		.icon-list-items {
			display: grid;
			grid-template-columns: 35% 57% 8%;  
			grid-column-gap: 40px;
			margin-bottom: 28px;
            @media (max-width: 420px) {
            	grid-template-columns: auto;  
			    grid-column-gap: 0;
            }
            &:not(:last-child) {
            	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            }

			.icon-list {
				align-items: baseline;
				 i {
                	color: var(--primary);
			        font-size: 10px;
                }
				.title {
					padding-bottom: 15px; 
					padding-left: 15px;
				}
			}

			.sub-title {
		       	margin: 0;
		       	font-family: var(--secondary-font);
		        color: var(--heading);
		        font-size: 20px;
		        font-weight: 600;
		        font-feature-settings: 'pnum' on, 'lnum' on;
		        padding-bottom: 12px;
		         @media (max-width: 420px) {
	            	padding-left: 24px;
	            }
		    }
	    }
	}

}