.ova-progress {
	width: 100%;
	.ctyl-progress-title {
		margin: 0;
		margin-bottom: 5px;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		color: var(--text);
	}

	.ova-percent-view {
		height: 13px;
		width: 100%;
		background-color: #201F3A;
		border-radius: 8px;
		overflow: visible;
		position: relative;

		.ova-percent {
			height: 100%;
			float: left;
			border-radius: 8px;
			background: -moz-linear-gradient(270deg, #63A3FF 0%, #011B3F 100%);
			background: -webkit-linear-gradient(270deg, #63A3FF 0%, #011B3F 100%);
			background: linear-gradient(270deg, #63A3FF 0%, #011B3F 100%);
		}

		.percentage {
			position: absolute;
			color: #fff;
			top: -30px;
			display: none;
			font-size: 16px;
			line-height: 24px;
			color: var(--text);
		}
	}

	.ova-notes {
		display: flex;
		margin-top: 18px;
		width: 100%;

		.item-note {
			position: relative;
			margin-left: 10%;
			.note-text {
				font-size: 14px;
				line-height: 21px;
				color: #fff;
			}
			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-left: 19%;
			}

			&:before {
		        content: "";
			    width: 1px;
			    height: 35px;
			    position: absolute;
			    top: -32px;
			    left: 50%;
			    border-left: 1px solid #414073;
			}
		}
	}
}