.ova-blog-slider-modern {

	.blog-slider-modern {
		position: relative;

		.owl-item {
           &.active-first {
	           	.item {
	           		.media {
	           			.mask{
	           				opacity: 1;
	           			}
	           		}
                    .info {
						opacity: 1;
						bottom: 40px;
					}
	           	}
			}
		}

		.item {
			position: relative;
			&:hover {
				.media {
                    .mask {
                        transition: all .3s ease;
	           		    opacity: 1;
	           		}
				}
				.info {
					transition: all .3s ease;
					-moz-transition: all .3s ease;
           	        -o-transition: all .3s ease;
					opacity: 1;
					bottom: 40px;
				}
           	}

           .media {
           	    position: relative;
	           	img {
	           		display: block;
	           	}
	           	.mask {
	           		transition: all .3s ease; 
	           		content:'';
	           		position: absolute;
	           		opacity: 0;
	           		top: 0;
	           		left: 0;
	           		width: 100%;
	           		height: 100%;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #000000 100%);
	           	}
           }

           .info {
           	    transition: all .3s ease;
           	    -moz-transition: all .3s ease;
           	    -o-transition: all .3s ease;
           	    position: absolute;
           	    bottom: 0;
           	    left: 24px;
           	    opacity: 0;

       			.post-title {
	           		margin: 0;
	           		padding-right: 24px;
	           		position: relative;
	           		&:before {
	           			position: absolute;
	           			content: '';
	           			left: 0;
	           			bottom: -12px;
	           			background-color: #fff;
	           			height: 1px;
	           			width: 82px;
	           		}
	           		a {
	           			font-family: var(--secondary);
	           			color: #fff;
						font-weight: 700;
						font-size: 22px;
						line-height: 32px;
						letter-spacing: 0.01em;
						font-feature-settings: 'pnum' on, 'lnum' on;
	           		}
	           		&:hover {
	           			a {
                            color: var(--primary);
	           			}
	                    &:before {
	                    	background-color: var(--primary);
	                    }
	           		}
	           	}

	           	.date {
	           		padding-left: 12px;
	           		border-left: 1px solid #dedede;
	           		font-family: var(--secondary);
           			color: #fff;
					font-weight: 600;
					font-size: 18px;
					line-height: 27px;
					font-feature-settings: 'pnum' on, 'lnum' on;
	           	}
            }
		}

		.owl-nav {
			transition: all .3s ease; 
			position: absolute;
			left: 0;
			top: 0;
			display: flex;
			justify-content: center;
			
			.owl-prev, .owl-next {
				display: flex;
				justify-content: center;
				border-radius: unset;
				align-items: center;
			    width: 40px;
			    height: 40px;
				background-color: #fff ;
				transition: all .3s ease;
				font-size: 20px;
				color: #000;
				&:hover {
					transition: all .3s ease;
					background-color: #000;
					color: #fff;
				}
			}
		}
	}
}