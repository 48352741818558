.ova-download-button {
	display: inline-flex;
	align-items: center;
	background-color: var(--primary);
	padding: 14px 18px;
	i {
		color: #fff;
		font-size: 24px;
		line-height: 1;
	}
	a {
		display: inline-block;
		padding-left: 12px;
		color: #fff;
		font-weight: 600;
		line-height: 19px;
		font-size: 16px;
		font-family: var(--secondary-font);
	}
	&:hover {
		box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
	}
}