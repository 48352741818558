.ova-transportation-slider {
	.transportation-slider {
		position: relative;
		.owl-item {
           .item {
           	    position: relative;
           	    overflow: hidden;
				&:hover {
					.transportation-slider-img {
						img {
							transition: all .3s ease;
				            -moz-transition: all .3s ease;
				            transform: scale(1.05,1.05);
				            -moz-transform: scale(1.05,1.05);
				            -o-transform: scale(1.05,1.05);
				            -ms-transform: scale(1.05,1.05);
						}
                        .mask {
                        	opacity: 0.95;
                        }
					}
					.info {
						transition: all .3s ease;
						-moz-transition: all .3s ease;
						-o-transition: all .3s ease;
						.title {
							&:before {
								height: 31px;
							}
							&:after {
								height: 19px;
							}
						}
					}
				}
				.transportation-slider-img {
					position: relative;
					img {
						display: block;
						overflow: hidden;
						transition: all .3s ease;
				        -moz-transition: all .3s ease;
						width: 100%;
					}
					.mask {
						transition: all .4s ease;
						content: "";
						background: linear-gradient(180deg, rgba(0, 0, 0, 0) 71.45%, rgba(0, 0, 0, 0.5) 100%);
				        position: absolute;
				        bottom: 0;
				        left: 0;
				        opacity: 1;
				        width: 100%;
				        height: 100%;
				    }
				}
				.info {
					transition: all .3s ease;
					-moz-transition: all .3s ease;
					-o-transition: all .3s ease;
					position: absolute;
					left: 40px;
					bottom: 45px;
					padding-right: 30px;
					@media screen and (max-width: 767px) {
						left: 30px;
						bottom: 30px;
					}

					.title {
						margin: 0;
						transition: all .3s ease;
						-moz-transition: all .3s ease;
						-o-transition: all .3s ease;
			            font-weight: 700;
			            color: #fff;
						font-size: 28px;
						line-height: 40px;
                        font-feature-settings: 'pnum' on, 'lnum' on;
					}

					.service {
						transition: all .4s ease;
						-moz-transition: all .4s ease;
						-o-transition: all .4s ease;
						margin: 0;
						color: #fff;
					    font-size: 18px;
					    font-weight: 500;
					    padding-bottom: 10px;
					}
				}
	        }
        }

		.owl-nav {
			transition: all .3s ease; 
			position: absolute;
			right: -36px;
			top: 0;
			display: flex;
			justify-content: center;
			@media screen and (max-width: 1024px) {
				right: 0;
			}
			.owl-prev, .owl-next {
				display: flex;
				justify-content: center;
				border-radius: unset;
				align-items: center;
			    width: 36px;
			    height: 36px;
				background-color: #fff ;
				transition: all .3s ease;
				font-size: 20px;
				color: #000;
				&:hover {
					transition: all .3s ease;
					background-color: rgba(0, 0, 0, 0.5);
					color: #fff;
				}
			}
		}
	}

}