.ova-network {
	background-color: #fff;
	padding: 28px;
	&:hover {
        box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.04);
        .continents {
            border-bottom: 1px solid var(--primary);
        }
	}
	.continents {
		margin: 0;
		font-weight: bold;
		font-size: 28px;
		line-height: 40px;
		letter-spacing: 0.01em;
		font-feature-settings: 'pnum' on, 'lnum' on;
		border-bottom: 1px solid rgba(0, 0, 0, 0.6);
		padding-bottom: 12px;
		margin-bottom: 32px; 
	}
	.country-list {
		margin: 0;
		padding: 0;
		padding-left: 20px;
		.country {
            font-family: var(--secondary-font);
			font-weight: 600;
			font-size: 18px;
			line-height: 27px;
			font-feature-settings: 'pnum' on, 'lnum' on;
			color: var(--heading);
			&:not(:last-child) {
              	border-bottom: 1px solid #ededed;
              	margin-bottom: 20px;
		        padding-bottom: 12px;
			}
			&::marker {
				color: var(--primary);
			}
		}
	}
}