.ova-portfolio{
    .grid {

        &:after {
            content: '';
            display: block;
            clear: both;
        }

        .grid-sizer, .grid-item {
            width: 50%;
        }

        .grid-item {
            float: left;
            padding-left: 30px;
            padding-bottom: 30px;

            .gallery-fancybox {
                display: block;
                position: relative;

                img {
                    display: block;
                    width: 100%;
                }

                .gallery-container {
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    cursor: pointer;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        display: block;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(0deg, rgba(71, 191, 111, 0.2), rgba(71, 191, 111, 0.2));
                        -webkit-transition: all .3s ease;
                        -moz-transition: all .3s ease;
                        -o-transition: all .3s ease;
                        transition: all .3s ease;
                        opacity: 0;
                    }

                    .gallery-icon {
                        opacity: 0;
                        visibility: hidden;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        -webkit-transition: all .3s ease;
                        -moz-transition: all .3s ease;
                        -o-transition: all .3s ease;
                        transition: all .3s ease;

                        i {
                            color: #fff;
                            font-size: 32px;
                        }
                    }
                }
            }

            &:hover {
                .gallery-fancybox {
                    .gallery-container {
                        &:before {
                            opacity: 1;
                        }

                        .gallery-icon {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 1024px) {
            .grid-item {
                width: 50%;
            }
        }

        @media screen and (max-width: 767px) {
            .grid-item {
                width: 100%;
            }
        }
    }
}

.fancybox__content img {
  cursor: zoom-in;
}

.fancybox__caption {
  text-align: center;
}