.ova-service {
	position: relative;
    transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;

	.service-img {
		position: relative;
		img {
           vertical-align: middle;
		   width: 100%;
		}
		.mask {
			transition: all .5s ease;
		    -moz-transition: all .5s ease;
		    -o-transition: all .5s ease;
			position: absolute;
		    content: '';
		    width: 100%;
		    height: 100%;
		    left: 0;
		    bottom: 0;
		    transition: all .3s ease;
		    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 75.05%, #000000 100%);
		    &:before {
		    	transition: all .3s ease;
				content: "";
		        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
				position: absolute;
				bottom: 0;
				left: 0;
				opacity: 0;
				width: 100%;
				height: 100%;
		    }
		}
	}

	&:hover {
		.info {
			.title {
				&:after {
					transition: all .5s ease;
					width: 70%;
				}
			}
		}
		.service-img {
			.mask {
				&:before {
					opacity: 1;
				}
			}
		}
		.service-icon {
	        -moz-transition: all .3s ease;
		    -o-transition: all .3s ease;
			transition: all .3s ease; 
			opacity: 1;
			top: 50%;
		}
	}

	.info {
		width: 100%;
		position: absolute;
		transition: all .3s ease;
		-moz-transition: all .3s ease;
		-o-transition: all .3s ease;
		left: 24px;
		bottom: 40px;

	    .title {
	    	margin: 0;
		    padding: 0 30px 0 27px;
		    color: #fff;
		    font-family: var(--secondary-font);
		    font-size: 22px;
		    font-weight: 700;
		    line-height: 26px;
		    font-feature-settings: 'pnum' on, 'lnum' on;
		    position: relative;
		    &:before {
		        position: absolute;
		        content: '';
		        left: 0;
		        bottom: 5px;
		        width: 21px;
			    height: 21px;
			    background: linear-gradient(to bottom left, transparent 0%, transparent 50%, #47bf6f 50%, #47bf6f 100%);
		    }
		     &:after {
		        position: absolute;
		        content: '';
		        width: 65px;
		        height: 1px;
		        left: 0;
		        bottom: -13px;
		        transition: all .3s ease;
		        background: #fff;
		    }
		}	
	}

	.service-icon {
		-moz-transition: all .3s ease;
	    -o-transition: all .3s ease;
		transition: all .3s ease; 
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        top: 60%;
        left: 50%;
        width: 60px;
        height: 60px;
        background-color: var(--primary);
        opacity: 0;
        transform: translate(-50%, -50%); 
        i {
	        color: #fff;
	        font-size: 24px;
	        line-height: 1;
		}
	}

	&.ova-service-template2 {
		.service-img {
			.mask {
				background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5) 100%);
			}
		}
		.info {
			.title {
				&:before {
                    background: linear-gradient(to top left, transparent 0%, transparent 50%, #47bf6f 50%, #47bf6f 100%);
				}
				&:after {
                    width: 85%;
                    background: rgba(255, 255, 255, 0.5);
				}
			}
		}
		&:hover {
			.info {
				.title {
					&:after {
						transition: all .3s ease;
						width: 85%;
						height: 2px;
						background: var(--primary);
						bottom: -14px;
					}  
				}
			}
		}
	}	
}