.ova-gallery-slider {
	.gallery-slider {
     
		.gallery-slider-img {
			img {
				display: block;
				width: 100%;
			}		
        }

		.owl-nav {
			transition: all .3s ease; 
			position: absolute;
			left: 0;
			top: 0;
			display: flex;
			justify-content: center;
			.owl-prev, .owl-next {
				display: flex;
				justify-content: center;
				border-radius: unset;
				align-items: center;
			    width: 40px;
			    height: 40px;
				background-color: #fff ;
				transition: all .3s ease;
				font-size: 20px;
				color: #000;
				&:hover {
					transition: all .3s ease;
					background-color: rgba(0, 0, 0, 0.5);
					color: #fff;
				}
			}
		}
	}

}